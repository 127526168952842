import { ContactForm } from "@/components/contact-form";
import { Layout } from "@/components/layout";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { GatsbySeo, JsonLd } from "gatsby-plugin-next-seo";
import React from "react";

const CareersPage = () => {
  return (
    <>
      <GatsbySeo
        title="Careers | Tectra Technologies"
        canonical="https://www.tectratechnologies.com/careers/"
        openGraph={{
          locale: "en_US",
          type: "article",
          url: "https://www.tectratechnologies.com/careers/",
          title: "Careers | Tectra Technologies",
        }}
        twitter={{
          handle: "@handle",
          site: "@TectraL",
          cardType: "summary_large_image",
        }}
      />
      <JsonLd
        json={{
          "@context": "https://schema.org",
          "@type": "WebSite",
          "@graph": [
            {
              "@type": "WebSite",
              "@id": "https://www.tectratechnologies.com/#website",
              url: "https://www.tectratechnologies.com/",
              name: "Tectra Technologies",
              description:
                "Web &amp; Mobile App, Cloud, UI/UX, Devops, Tax Services, Digital Marketing",
              potentialAction: [
                {
                  "@type": "SearchAction",
                  target: {
                    "@type": "EntryPoint",
                    urlTemplate:
                      "https://www.tectratechnologies.com/?s={search_term_string}",
                  },
                  "query-input": "required name=search_term_string",
                },
              ],
              inLanguage: "en-US",
            },
          ],
        }}
      />
      <Layout>
        <div className="bg-white mt-28 lg:mt-32">
          <div className="max-w-7xl mx-auto px-5 lg:px-8">
            <h2 className="text-black text-center text-3xl font-poppins font-semibold captalize tracking-tight">
              Working with us, <br />
              the awesome team
            </h2>
            <p className="text-lg text-center font-poppins font-thin mt-4">
              Our focus is always on finding the best people to work with. Our
              bar
              <br />
              is high, but you look ready to take on the challenge.
            </p>
          </div>
        </div>

        <div className="bg-transparent mt-10 lg:mt-16">
          <div className="w-full">
            <StaticImage
              src="../../images/careers_hero.png"
              quality={95}
              formats={["auto", "png"]}
              alt="Tectra Technologies"
              height={800}
              className="w-full h-60 md:h-92 lg:h-auto"
            />
          </div>
        </div>

        <div className="bg-transparent my-10 lg:my-12">
          <div className="max-w-7xl mx-auto px-5 lg:px-8">
            <h2 className="text-black text-left text-2xl font-poppins font-semibold captalize tracking-tight">
              Opportunities
            </h2>
            <div className="max-w-2xl mt-4">
              <div className="flex flex-wrap items-center justify-between p-5 border-2 border-gray-100">
                <Link
                  to="/careers/fullstack-enginner"
                  className="text-lg text-center text-black font-poppins font-medium hover:text-black"
                >
                  <span className="mr-5">Full Stack Engineer</span>
                </Link>
              </div>
              <div className="flex flex-wrap items-center justify-between p-5 border-2 border-gray-100">
                <Link
                  to="/careers/php-developer"
                  className="text-lg text-center text-black font-poppins font-medium hover:text-black"
                >
                  <span className="mr-5">PHP Developer</span>
                </Link>
              </div>
              <div className="flex flex-wrap items-center justify-between p-5 border-2 border-gray-100">
                <Link
                  to="/careers/business-analyst"
                  className="text-lg text-center text-black font-poppins font-medium hover:text-black"
                >
                  <span className="mr-5">Business Analyst</span>
                </Link>
              </div>
              <div className="flex flex-wrap items-center justify-between p-5 border-2 border-gray-100">
                <Link
                  to="/careers/business-development"
                  className="text-lg text-center text-black font-poppins font-medium hover:text-black"
                >
                  <span className="mr-5">Business Development</span>
                </Link>
              </div>
              <div className="flex flex-wrap items-center justify-between p-5 border-2 border-gray-100">
                <Link
                  to="/careers/flutter-developer"
                  className="text-lg text-center text-black font-poppins font-medium hover:text-black"
                >
                  <span className="mr-5">Flutter Developer</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default CareersPage;
